// Import a Google Font -- move out of here
@import url('//fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;900&display=swap');

$primary-color: #7510f7;
$white: #ffffff;
$scarlet: #f52500;
$black: #212121;
$dark: #151515;
$link: #141c3a;

// Update Bulma's global variables
$family-sans-serif: 'Poppins', sans-serif;
$primary: $primary-color;
$link: $link !important;
$widescreen-enabled: true;
$fullhd-enabled: false;

// Bulma IO framework
@import '../node_modules/bulma/bulma.sass';

html,
body {
  font-family: 'Inter', sans-serif !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}
// * {
//   transition: all 0.3s ease-in-out;
// }
.App {
  header {
    box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.15) !important;
  }
  .projects {
    justify-content: space-around;
  }

  a.project_link {
    &:hover {
      .project-image {
        transform: scale(1.05);
        transition: transform 0.2s ease-in;
      }
    }
  }

  picture {
    .project-image {
      border-radius: 12px;
      transition: transform 0.2s ease-in;
      box-shadow: -15px 30px 26px -30px rgba(43, 49, 56, 0.32);
      width: 100%;
    }
  }
}

.full-width {
  width: 100%;
}
.modal-image {
  cursor: pointer;
}
.navbar-item {
  a:hover {
    color: $link !important;
  }
  .button.is-primary.is-outlined:hover {
    color: $white !important;
  }
  img {
    max-height: 2.65rem;
  }
}
